import { _axios as axios } from '@/plugins/axios.js'
import router from '@/router/index.js';





function getInitialState() {
    return {
        newCard: {

            accepter: {
                name: 'Khurin',
                org: 'Delta 1',
                post: 'Constructor',
                date: '22.02.1984',
            },
            agreeder: {
                name: 'Smirnov',
                org: 'Delta 2',
                post: 'Director',
                date: '19.05.1977',
            },
            name: 'TEST CARD NAME',
            about: 'Something about card and it destenee',
            weldMethod: { name: '', id: 0 },
            gost: { name: '', id: 0 },
            joinType: { name: '', id: 0 },
            seamAndView: { type: '', view: '', seamId: 0 },
            jointPosition: { name: '' },
            element_1: { typeEl: '', viewEl: '', markMetal: '', groupEl: '', d: '', s: '', corner: '' },
            element_2: { typeEl: '', viewEl: '', markMetal: '', groupEl: '', d: '', s: '', corner: '' },
            error: false,
            errorMessege: '',
        },
        currentStep: 1,
        stepsCount: 4,


    }
}



export default {
    namespaced: true,
    state: getInitialState(),

    actions: {
        nextStep({ state, commit }) {
            if (state.currentStep == 3) {
                axios.get("/sapr-gost/card/verification", {
                        params: {
                            weldmethodId: state.newCard.weldMethod.id,
                            gostId: state.newCard.gost.id,
                            joinTypeId: state.newCard.joinType.id,
                            S1: state.newCard.element_2.s,
                            S: state.newCard.element_1.s,
                            D1: state.newCard.element_1.d,
                            D2: state.newCard.element_2.d,
                        },
                    })
                    .then((response) => {
                        if (response.data.messege == 'ok') {

                            commit('nextStep')
                            router.push({
                                query: {
                                    ...router.currentRoute.query,
                                    step: state.currentStep
                                }
                            })
                            localStorage.setItem('gostCard', JSON.stringify(state))
                        } else {
                            state.newCard.error = true
                            state.newCard.errorMessege = response.data
                            setTimeout(() => state.newCard.error = false, 5000)
                            console.log(state.newCard.errorMessege)
                        }
                    })

            } else {
                commit('nextStep')
                router.push({
                    query: {
                        ...router.currentRoute.query,
                        step: state.currentStep
                    }
                })
                localStorage.setItem('gostCard', JSON.stringify(state))
            }



        },
        prevStep({ state, commit }) {
            commit('prevStep')
            router.push({
                query: {
                    ...router.currentRoute.query,
                    step: state.currentStep
                }
            })
            localStorage.setItem('gostCard', JSON.stringify(state))
        },
        setStep({ state, commit }, step) {

            commit('setStep', step)
            router.push({
                query: {
                    ...router.currentRoute.query,
                    step: state.currentStep
                }
            })
            localStorage.setItem('gostCard', JSON.stringify(state))

        }

    },

    mutations: {
        prevStep(state) {
            state.currentStep--
        },
        nextStep(state) {
            state.currentStep++

        },
        setStep(state, step) {
            state.currentStep = step
        },

        setNewCardAccepterField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard.accepter[key] = value
            }
        },
        setNewCardAgreederField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard.agreeder[key] = value
            }
        },
        setNewCardField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard[key] = value
            }
        },
        setNewCardElementField_1(state, payload) {

            for (let [key, value] of Object.entries(payload)) {
                state.newCard.element_1[key] = value
            }


        },
        setNewCardElementField_2(state, payload) {

            for (let [key, value] of Object.entries(payload)) {
                state.newCard.element_2[key] = value
            }
        }
    }

}