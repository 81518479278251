import navbarItems from './navbarItems.js'

export default {
  path: '/admin',
  meta: { private: true, roles: ['admin'] },
  props: { navbarItems: navbarItems },
  component: () => import('@/layouts/main/Index.vue'),
  children: [
    {
      path: 'index',
      component: () => import('./views/Index.vue')
    },
    {
      path: 'device/index',
      component: () => import('./views/device/Index.vue')
    },
  ]
}
