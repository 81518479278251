import router from '@/router/index.js'
import { _axios as axios} from '@/plugins/axios.js'

function getInitialState() {
  return {
    selected: false,
    // profile: { id: '', logo: null, title: '' },
    profile: null,
    modulesIds: [],
    activeModule: {},
  }
}

const lastOrg = JSON.parse(localStorage.getItem('organization'))

export default {
  namespaced: true,
  state: lastOrg || getInitialState(),
  getters: {
    title: state => state.profile && state.profile.title,
    isSelected: state => !!state.profile || false
  },
  actions: {
    selectModule({ commit, state }, module) {
      if(state.activeModule && module.id !== state.activeModule.id) {
        commit('selectModule', module)
        localStorage.setItem('organization', JSON.stringify(state))
      }
      router.push(module.baseUrl + (module.indexUrl || '')).catch(() => {})
    },
    select({ dispatch, commit, state, rootState }, orgId) {
      if (!rootState.user.organizationsIds.includes(orgId)) {
        dispatch('setAlert', {
          message: 'Вы не состоите в выбранной организации',
          type: 'error'
        }, { root: true })
        return
      }
      dispatch('logout').then(() => {
        axios.post('user/organization/select', { orgId: orgId }).then(r => {
            commit('setProfile', r.data.profile)
            commit('setModulesIds', r.data.modulesIds)
            commit('setSelected', true)
            dispatch('user/selectModule', null, { root: true })
            localStorage.setItem('organization', JSON.stringify(state))
            router.push('/').catch(() => {})
        })
      })
    },
    logout({ commit }) {
      return axios.post('user/organization/logout').then(() => {
        commit('logout')
        localStorage.removeItem('organization')
      })
    },
  },
  mutations: {
    selectModule(state, value) {
      state.activeModule = value
    },
    setSelected(state, value) {
      state.selected = value
    },
    setModulesIds(state, value) {
      state.modulesIds = value.slice()
    },
    setProfile(state, profile) {
      state.profile = profile
    },
    logout(state) {
      const d = getInitialState()
      Object.keys(state).forEach(key => {
        if(d.hasOwnProperty(key)) {
          state[key] = d[key]
        } else {
          delete state[key]
        }
      })
    },
  }
}
