import Vue from 'vue'
import router from '@/router/index.js'
import Vuex from 'vuex'
import user from '@/modules/user/store.js'
import organization from '@/modules/organization/store.js'
import specialCard from '@/modules/specialCard/store.js'
import simpleCard from '@/modules/simpleCard/store.js'
import gostCard from '@/modules/gostCard/store.js'

Vue.use(Vuex)

const alertDuartion = 3000;

export default new Vuex.Store({
  modules: {
    user,
    organization,
    simpleCard,
    specialCard,
    gostCard,
  },
  state: {
    apiRequestCounter: 0,
    alerts: [],
    showMainMenu: false,
    showAvatarMenu: false,
    modules: [],
    layersNames: [],
    polaritiesNames: [],
    channelsNames: [],
    orgPositionsNames: [],
    roles: [],
  },
  getters: {
    getLayerNameById: state => id => {
      let l = state.layersNames.find(l => l.id == id)
      return l ? l.name : 'Низвестный слой'
    },
    getChannelNameById: state => id => {
      let ch = state.channelsNames.find(ch => ch.id == id)
      return ch ? ch.name : 'Низвестный канал'
    },
    getPolarityNameById: state => id => {
      let p = state.polaritiesNames.find(pItem => pItem.id == id)
      return p ? p.name : 'Низвестная полярность'
    },
    alert: state => state.alerts[0],
    getModules: state => {
      if (state.organization.selected) {
        return state.modules.filter(m => state.organization.modulesIds.includes(m.id))
      }
      return state.modules.filter(m => state.user.modulesIds.includes(m.id))
    },
    getModule: state => id => state.modules.find(m => m.id == id),
    getActiveModule: state => {
      if (state.organization.selected) {
        return state.organization.activeModule
      }
      return state.user.activeModule
    }
  },
  actions: {
    selectModule({ dispatch, state }, m) {
      if (state.organization.selected) {
        dispatch('organization/selectModule', m, { root: true })
      } else {
        dispatch('user/selectModule', m, { root: true })
      }
      if (m && m.baseUrl) {
        router.replace(m.baseUrl + m.indexUrl).catch(() => {})
      }
    },
    setAlert({ commit }, alert) {
      const timerId = setTimeout(() => {
        commit('removeAlert')
      }, alertDuartion)
      commit('setAlert', {
        message: alert.message,
        type: alert.type,
        timerId: timerId
      })
    },
    init({ commit, state }) {
      axios.get('main/init').then(r => {
        commit('setModules', r.data.modules)
        commit('setLayersNames', r.data.layersNames)
        commit('setPolaritiesNames', r.data.polaritiesNames)
        commit('channelsNames', r.data.channelsNames)
        commit('orgPositionsNames', r.data.orgPositionsNames)
        commit('rolesNames', r.data.rolesNames)
      })
    },
  },
  mutations: {
    rolesNames(state, payload) {
      state.rolesNames = payload.slice()
    },
    setModules(state, payload) {
      state.modules = payload.slice()
    },
    setPolaritiesNames(state, polaritiesNames) {
      state.polaritiesNames = polaritiesNames.slice()
    },
    setLayersNames(state, layersNames) {
      state.layersNames = layersNames.slice()
    },
    channelsNames(state, channelsNames) {
      state.channelsNames = channelsNames.slice()
    },
    orgPositionsNames(state, orgPositionsNames) {
      state.orgPositionsNames = orgPositionsNames
    },
    setShowMainMenu(state, value) {
      state.showMainMenu = value
    },
    setShowAvatarMenu(state, value) {
      state.showAvatarMenu = value
    },
    setAlert(state, alert) {
      state.alerts.push(alert)
    },
    removeAlert(state) {
      if (state.alerts.length > 0) {
        clearTimeout(state.alerts[0].timerId)
        state.alerts.splice(0, 1)
      }
    },
    incAPIRequestCounter(state) {
      state.apiRequestCounter++
    },
    decAPIRequestCounter(state) {
      state.apiRequestCounter--
    },
  }
})
