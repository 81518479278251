export default {
  path: '/special-card',
  component: () => import('@/layouts/main/Index.vue'),
  meta: { private: true },
  redirect: '/special-card/index',
  children: [
    {
      path: 'card',
      redirect: 'card/index'
    },
    {
      path: 'card/index',
      component: () => import('./views/Index.vue')
    },
    {
      path: 'card/create',
      meta: 'create',
      component: () => import('./views/CreateUpdateCopy.vue'),
    },
    {
      path: 'card/view',
      component: () => import('./views/View.vue'),
    },
    {
      path: 'card/update',
      meta: 'update',
      component: () => import('./views/CreateUpdateCopy.vue'),
    },
    {
      path: 'card/copy',
      meta: 'copy',
      component: () => import('./views/CreateUpdateCopy.vue'),
    },
    // {
    //   path: 'card/create/',
    //   component: () => import('./views/Create.vue'),
    //   children: [
    //     {
    //       path: '*',
    //       components: {
    //         step1: () => import('./views/components/steps/Step1.vue'),
    //         step2: () => import('./views/components/steps/Step2.vue'),
    //         step3: () => import('./views/components/steps/Step3.vue'),
    //         step4: () => import('./views/components/steps/Step4.vue'),
    //         step5: () => import('./views/components/steps/Step5.vue'),
    //         step6: () => import('./views/components/steps/Step6.vue'),
    //         step7: () => import('./views/components/steps/Step7.vue'),
    //       },
    //     },
    //   ]
    // }
  ]
}
