import Vue from 'vue'
import Store from '@/store'
import VueRouter from 'vue-router'
import UserRoutes from '@/modules/user/routes.js'
import OrganizationRoutes from '@/modules/organization/routes.js'
import ManufactureRoutes from '@/modules/manufacture/routes.js'
import SimpleCardRoutes from '@/modules/simpleCard/routes.js'
import SpecialCardRoutes from '@/modules/specialCard/routes.js'
import AdminRoutes from '@/modules/admin/routes.js'
import GostCard from '@/modules/gostCard/routes.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    redirect: '/user/account/login',
  },
  {
    path: '/signup',
    redirect: '/user/account/signup',
  },
  {
    path: '/email-confirm',
    redirect: '/user/account/email-confirm',
  },
  {
    path: '/password-reset-request',
    redirect: '/user/account/password-reset-request',
  },
  {
    path: '/password-reset',
    redirect: '/user/account/password-reset',
  },
  UserRoutes,
  OrganizationRoutes,
  ManufactureRoutes,
  SimpleCardRoutes,
  SpecialCardRoutes,
  AdminRoutes,
  GostCard,
  {
    path: '/modules',
    meta: { private: true },
    component: () => import ('@/layouts/main/Index.vue'),
    children: [
      {
        path: 'index',
        component: () => import ('@/views/modules/Index.vue')
      },
      {
        path: 'simple-card',
        component: () => import ('@/views/modules/components/simpleCard/Index.vue')
      },
      {
        path: 'special-card',
        component: () => import ('@/views/modules/components/specialCard/Index.vue')
      },
      {
        path: 'manufacture',
        component: () => import ('@/views/modules/components/manufacture/Index.vue')
      },
    ]
  },
  {
    path: '/',
    meta: { private: true },
    component: () => import('@/layouts/main/Index.vue')
  },
  {
    path: '*',
    name: 'Not found',
    component: () => import ('@/components/NotFound.vue')
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // console.log(from, to)
    var loggedIn = Store.state.user.loggedIn
    if (loggedIn && (to.path.includes('login') || to.path.includes('signup'))) {
        router.replace('/')
    } else if (!loggedIn && to.matched.some((route) => route.meta.private)) {
        next('/user/account/login')
    } else {
        next()
    }
})

router.afterEach((to, from, next) => {
    localStorage.setItem('lastRoute', to.fullPath)
});

export default router
