import { _axios as axios } from '@/plugins/axios.js'
import router from '@/router/index.js';


const jointTypeItems = [
    'С',
    'У',
    'Т',
    'Н',
    'Ууг',
    'Туг',
]
const gostItems = [
    'ГОСТ 1',
    'ГОСТ 2',
]
const seamTypeItems = [
    'СШ',
    'УШ',
    'ТШ',
]
const seamKindItems = [
    'Ос, сп',
    'Ос',
]
const weldPositionItems = [
    'Н1',
    'Н2',
    'Г',
    'П1',
    'П2',
    'В1',
    'В2',
    'Н45',
]
const weldMethodItems = [
    'РД',
    'РАД',
    'МП',
    'МАДП',
    'ФВ',
]
const elementTypeItems = [
    'Труба',
    'Стержень',
    'Лист',
]
const materialItems = [
    'M01',
    'M11',
    'M01+M11',
    'Ст3сп',
    '12X18H10T',
]
const postHeatTypeItems = [
    //
    'Супер', 'Средняя', 'Чмошная',
]
const postHeatCoolingItems = [
    //
    'Смотрим вмнимательно', 'Как пойдет', 'Да ваще пох',
]

function getInitialState() {
    return {
        newCard: {
            // Step 1
            accepter: {
                name: 'Khurin',
                org: 'Delta 1',
                post: 'Constructor',
                date: '22.02.1984',
            },
            agreeder: {
                name: 'Smirnov',
                org: 'Delta 2',
                post: 'Director',
                date: '19.05.1977',
            },
            name: 'TEST CARD NAME',
            about: 'Something about card and it destenee',
            files: [],
            // Step 2
            weldMethod: 'РД',
            jointType: 'С',
            normDoc: 'ГОСТ 5264-80',
            seamType: 'СШ',
            seamKind: 'Ос, сп',
            weldPosition: 'Н1',
            elements: [
                { num: 1, type: 'Труба', material: 'M11', d: '13', s: '14' },
                { num: 2, type: 'Труба', material: 'Ст3сп', d: '23', s: '24' },
            ],
            //setp 3
            jointConstruct: {
                id: 2,
                name: 'C5',
                jointImage: 'http://',
                jointText: 'Размеры для соединения',
                seamImage: 'http://',
                seamText: 'Размеры для шва',
            },
            weldOrder: [
                { id: 1, name: 'Порядок 1', img: 'http://', text: 'Примечание к порядку 1' }
            ],
            weldOrderCommit: 'Общее примечание к порядку сварки',
            // step 4
            edgeRequire: 'Какие-то требования к кромкам',
            assemblyRequire: 'Какие-то требования к сборке',
            tackweldRequire: 'Какие-то требования к прихватке',
            weldRequire: 'Какие-то требования к сварки',
            // step 5
            layers: [{
                num: 1,
                nameId: 1,
                weldMaterial: 'M0',
                electrode: 3,
                polarityNameId: 1,
                channels: [
                    { nameId: 1, min: 50, max: 100 }
                ]
            }, ],
            // step 6
            weldEquipment: [
                { id: 1, name: "СуперСварочныйАппарат" },
                { id: 3, name: "DC - 200" }
            ],
            preHeat: 'Ну там какой-то подогрев',
            postHeatType: 'Супер',
            postHeatCooling: 'Как пойдет',
            postHeatEquipment: [
                { id: 3, name: 'Хороший прогреватель' }
            ],
            postHeatCycles: [
                { temperature: '10', delay: '100c' },
            ],
            // step 7
            operationControl: 'Что-то там по контролю',
            safety: 'Подробный рассказ про технику безопасности и права и обязанности работника',
        },


        // ITEMS FOR SELECT ////////////////////////
        // step 1
        jointTypeItems,
        // step 2
        weldMethodItems,
        jointTypeItems,
        gostItems,
        seamTypeItems,
        seamKindItems,
        weldPositionItems,
        elementTypeItems,
        materialItems,
        // step 6
        postHeatTypeItems,
        postHeatCoolingItems,
        ////////////////////////////////////////////

        // Main variables
        fromUrl: '',
        currentStep: 1,
        stepsCount: 7
    }
}

// const lastState = null
// const lastState = axios.get('/special-card/card/view')
const lastState = JSON.parse(localStorage.getItem('specialCard'))

export default {
    namespaced: true,
    state: lastState || getInitialState(),
    actions: {
        removeFile({ state, commit }, file) {
            console.log(file)
            if (file.new) {
                axios.post('file/delete', { url: file.url }).then(() => {
                    commit('removeFile', file.name)
                })
            } else {
                let f = state.newCard.files.find(item => item.name == file.name)
                axios.post('special-card/card/delete-file', {
                    versionId: f.cardVersionId,
                    fileId: f.id
                }).then(() => {
                    commit('removeFile', f.name)
                })
            }
        },
        addFile({ state, commit }, file) {
            commit('addFile', file)
            localStorage.setItem('specialCard', JSON.stringify(state))
        },
        nextStep({ state, commit }) {
            commit('nextStep')
            router.push({
                query: {
                    ...router.currentRoute.query,
                    step: state.currentStep
                }
            })
            localStorage.setItem('specialCard', JSON.stringify(state))
        },
        prevStep({ state, commit }) {
            commit('prevStep')
            router.push({
                query: {
                    ...router.currentRoute.query,
                    step: state.currentStep
                }
            })
            localStorage.setItem('specialCard', JSON.stringify(state))
        },
        setStep({ state, commit }, step) {
            commit('setStep', step)
            router.push({
                query: {
                    ...router.currentRoute.query,
                    step: state.currentStep
                }
            })
            localStorage.setItem('specialCard', JSON.stringify(state))
        },
        resetNewCard({ state, commit }) {
            commit('resetNewCard')
            localStorage.setItem('specialCard', JSON.stringify(state))
        },
        loadCard({ commit }, id) {
            return axios.get('/special-card/card/view', { params: { id: id } })
                .then(r => {
                    commit('setCard', r.data.version)
                })
        }
    },
    mutations: {
        addWeldOrder(state, wo) {
            if (state.newCard.weldOrder.find(item => item.id == wo.id)) {
                // alert('Такой порядок уже существует')
                return
            }
            state.newCard.weldOrder.push(Object.assign({}, wo))
        },
        removeWeldOrder(state, wo) {
            let index = state.newCard.weldOrder.findIndex(item => item.id == wo.id)
            state.newCard.weldOrder.splice(index, 1)
        },
        removeFile(state, fileName) {
            let index = state.newCard.files.findIndex(f => f.name == fileName)
            state.newCard.files.splice(index, 1)
        },
        addFile(state, file) {
            state.newCard.files.push(Object.assign({}, file))
        },
        setFromUrl(state, payload) {
            state.fromUrl = payload.fullPath
        },
        setCard(state, payload) {
            state.newCard = Object.assign({}, payload)
        },
        setNewCardAccepterField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard.accepter[key] = value
            }
        },
        setNewCardAgreederField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard.agreeder[key] = value
            }
        },
        setNewCardField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard[key] = value
            }
        },
        setNewCardElementField(state, payload) {
            // for (let [index, obj] of Object.entries(payload)) {
            let [index, obj] = Object.entries(payload)[0]
            for (let [key, value] of Object.entries(obj)) {
                state.newCard.elements[index][key] = value
            }
            // }
        },
        newCardLayerCreate(state) {
            state.newCard.layers.push(JSON.parse(JSON.stringify({
                num: state.newCard.layers.length + 1,
                nameId: null,
                polarityNameId: null,
                electrode: null,
                channels: [
                    { nameId: 1, min: '', max: '' }
                ],
            })))
        },
        newCardLayerUp(state, num) {
            if (num === 0) return
            var index = state.newCard.layers.findIndex(l => l.num === num)
            state.newCard.layers[index - 1].num += 1
            state.newCard.layers[index].num -= 1
        },
        newCardLayerDown(state, num) {
            if (num >= state.newCard.layers.length) return
            var index = state.newCard.layers.findIndex(l => l.num === num)
            state.newCard.layers[index].num += 1
            state.newCard.layers[index + 1].num -= 1
        },
        newCardLayerCopy(state, num) {
            var index = state.newCard.layers.findIndex(l => l.num === num)
            var item = JSON.parse(JSON.stringify(state.newCard.layers[index])) //PPC COSTYLI(deep copy)
            state.newCard.layers.forEach(l => {
                if (l.num > index) {
                    l.num++
                }
            })
            item.num = num + 1
            item.id = ''
            state.newCard.layers.push(item)
        },
        newCardLayerRemove(state, num) {
            var index = state.newCard.layers.findIndex(l => l.num === num)
            state.newCard.layers.forEach(l => {
                if (l.num > index) {
                    l.num--
                }
            })
            state.newCard.layers.splice(index, 1)
        },
        setNewCardLayerField(state, payload) {
            let [index, obj] = Object.entries(payload)[0]
            for (let [key, value] of Object.entries(obj)) {
                state.newCard.layers[index][key] = value
            }
        },
        newCardLayerChannelCreate(state, layerIndex) {
            state.newCard.layers[layerIndex].channels.push(
                Object.assign({}, {
                    nameId: state.newCard.layers[layerIndex].channels.length + 1,
                    min: '',
                    max: ''
                })
            )
        },
        setNewCardLayerChannelField(state, payload) {
            var chIndex = state.newCard.layers[payload.layerIndex].channels.findIndex(
                ch => ch.nameId == payload.channelNameId
            )
            for (let [key, value] of Object.entries(payload.value)) {
                state.newCard.layers[payload.layerIndex].channels[chIndex][key] = value
            }
        },
        addNewCardWeldEquipment(state, payload) {
            state.newCard.weldEquipment = payload.slice()
        },
        addNewCardPostHeatCycle(state) {
            state.newCard.postHeatCycles.push(Object.assign({}, { temperature: '', delay: '' }))
        },
        setNewCardPostHeatField(state, payload) {
            for (let [key, value] of Object.entries(payload)) {
                state.newCard.postHeat[key] = value
            }
        },
        addNewCardPostHeatEquipment(state, payload) {
            state.newCard.postHeatEquipment = payload.slice()
        },
        setNewCardPostHeatCycleField(state, payload) {
            let [index, obj] = Object.entries(payload)[0]
            for (let [key, value] of Object.entries(obj)) {
                state.newCard.postHeatCycles[index][key] = value
            }
        },
        resetNewCard(state) {
            state.currentStep = 1
            state.newCard = Object.assign({}, getInitialState().newCard)
        },
        prevStep(state) {
            state.currentStep--
        },
        nextStep(state) {
            state.currentStep++
        },
        setStep(state, step) {
            state.currentStep = step
        }
    }
}