import router from '@/router/index.js'
import { _axios as axios } from '@/plugins/axios.js'

function getInitialState() {
    return {
        loggedIn: false,
        role: '',
        profile: {},
        modulesIds: [],
        activeModule: {},
    }
}

const lastUser = JSON.parse(localStorage.getItem('user'))

export default {
    namespaced: true,
    state: lastUser || getInitialState(),
    getters: {
        // name: state => `${state.profile.firstname} ${state.profile.middlename} ${state.profile.lastname}`,
        name: state => `${state.profile.firstname} ${state.profile.lastname}`,
        image: state => state.profile.image || null,
        thumbnail: state => state.profile.thumbnail || null,
        isAdmin: state => state.role === 'admin',
    },
    actions: {
        setProfile({ state, commit }, profile) {
            commit('setProfile', profile)
            localStorage.setItem('user', JSON.stringify(state))
        },
        selectModule({ state, commit }, module) {
            commit('selectModule', module)
            localStorage.setItem('user', JSON.stringify(state))
        },
        login({ dispatch, state, commit }, userData) {
            return axios.post('user/account/login', userData).then(r => {
                commit('setUser', r.data)
                localStorage.setItem('user', JSON.stringify(state))
                router.push('/')
            })
        },
        logout({ commit }) {
            commit('logout')
            localStorage.removeItem('user')
            window.location.href = '/'
        },
    },
    mutations: {
        addOrgId(state, orgId) {
            state.organizationsIds.push(orgId)
        },
        selectModule(state, module) {
            state.activeModule = module
        },
        setUser(state, data) {
            state.profile = data.profile
            state.modulesIds = data.modulesIds.slice()
            state.organizationsIds = data.organizationsIds.slice()
            state.role = data.role
            state.loggedIn = true
        },
        setProfile(state, data) {
            state.profile = data
        },
        logout(state) {
            const d = getInitialState()
            Object.keys(state).forEach(key => {
                if (d.hasOwnProperty(key)) {
                    state[key] = d[key]
                } else {
                    delete state[key]
                }
            })
        },
    }
}
