import Vue from 'vue'
import axios from 'axios'
import router from '@/router/index.js'
import store from '@/store/index.js'

const config = {
  baseURL: '/api',
  // timeout: 60 * 1000, // Timeout
  withCredentials: true,
}

export const _axios = axios.create(config)

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    store.commit('incAPIRequestCounter')
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    store.commit('decAPIRequestCounter')
    if (response.data.message) {
      store.dispatch('setAlert', { message: response.data.message, type: 'info' })
    }
    return response
  },
  function(error) {
    if(error.response.status == 401) {
      store.dispatch('user/logout')
    }
    store.commit('decAPIRequestCounter')
    if (error.response) {
      store.dispatch('setAlert', { message: error.response.data.message, type: 'error' })
    }
    return Promise.reject(error)
  }
)

Plugin.install = function(Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    },
  })
}

Vue.use(Plugin)

export default Plugin
