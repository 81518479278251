export default {
  path: '/simple-card',
  component: () => import('@/layouts/main/Index.vue'),
  meta: { private: true },
  children: [
    {
      path: 'card/index',
      component: () => import('./views/Index.vue')
    },
    {
      path: 'card/create',
      component: () => import('./views/Create.vue')
    },
    {
      path: 'card/view',
      component: () => import('./views/View.vue')
    },
    {
      path: 'card/update',
      component: () => import('./views/Update.vue')
    },
    {
      path: 'card/copy',
      component: () => import('./views/Copy.vue')
    }
  ]
}
