export default {
  path: '/organization',
  meta: { private: true },
  component: () => import('@/layouts/main/Index.vue'),
  children: [
    {
      path: 'profile/create',
      component: () => import('./views/profile/Create.vue')
    },
    {
      path: 'profile/update',
      component: () => import('./views/profile/Update.vue')
    },
    {
      path: 'staff/index',
      component: () => import('./views/staff/Index.vue')
    },
    {
      path: 'device/index',
      component: () => import('./views/device/Index.vue')
    },
  ]
}
